/* Classe RAD
 * Definisce il collegamento a basso livello con il server
 * Autore:  Fiorelli Luigi
 * Data:    01/09/2017
 * Agg :    06/10/2017
 * V   :    1.1.9
 */

var RAD = {
    data: {
        //url: "http://192.168.1.6:5000/MyWeb/www/App_dev/AppBeckend/rimedia-app-backend/dialog.php",
        url: "https://www.rimediagroup.info/app/Backend/dialog.php",
        logged: false,
        id_usr: -1,
        email: "",
        telefono: "",
        citta: "",
        password: "",
        password512: "",
        log_code_pre: "",
        error: ""
    },
    set_email: function(val){
        RAD.data.email = val;
    },
    set_password: function(val){
        //RAD.data.password = val;
        //RAD.data.password512 = sha512(val);
        RAD.data.password = val;
        RAD.data.password512 = val;
    },
    get_error: function(){
        return RAD.data.error;
    },
    req_login: function(f_ok,f_fail){
        App.io.log("Login " + RAD.data.password512);
        if(RAD.data.log_code_pre !== ""){
            req_code = '2';
        } else {
            req_code = '1';
        }
        $.ajax({
            type: "POST",
            url: RAD.data.url,
            data: {  
                req_code: req_code,
                email: RAD.data.email, 
                log_code: sha512("sha512" + RAD.data.password512 + RAD.data.log_code_pre)
            },
            dataType: 'json'
        })
        .done(function( msg ) {
            RAD.data.log_code_pre = msg.loginValue.log_code;
            if(msg.loginValue.logged == 0){
                RAD.data.logged = false;
                f_fail("");
            } else {
                
                RAD.data.logged = true;
                f_ok(msg.loginValue);
            }
        })
        .fail(function( jqXHR, textStatus ) {
                RAD.data.logged = false;
                App.io.log("Login Fail - " + textStatus);
                f_fail(textStatus);
            }
        );
    },
    req_trib_list: function(f_ok,f_fail){
        req_code = '10';
        $.ajax({
            type: "POST",
            url: RAD.data.url,
            data: {  
                req_code: req_code,
                email: RAD.data.email, 
                log_code: sha512("sha512" + RAD.data.password512 + RAD.data.log_code_pre)
            },
            dataType: 'json'
        })
        .done(function( msg ) {
            RAD.data.log_code_pre=msg.loginValue.log_code;
            if(msg.loginValue.logged == 0){
                //App.io.log("Tipo Trib not logged");
                RAD.data.logged = false;
                f_fail();
            } else {
                //App.io.log("Tipo Trib ok");
                /*Salvataggio dati in variabile*/
                f_ok(msg.dati);
            }
        })
        .fail(function( jqXHR, textStatus ) {
                RAD.data.logged = false;
                App.io.log("Tipo Trib Fail - " + textStatus);
                f_fail();
            }
        );
    },
    req_reg: function(f_ok,f_fail){
        req_code = '3';
        $.ajax({
            type: "POST",
            url: RAD.data.url,
            data: {  
                req_code: req_code,
                email: RAD.data.email, 
                password512: RAD.data.password512,
                nome: RAD.data.nome,
                cognome: RAD.data.cognome,
                telefono: RAD.data.telefono,
                citta: RAD.data.citta,
                ac_pr: RAD.data.ac_pr,
                ac_ne: RAD.data.ac_ne,
                ac_co: RAD.data.ac_co
            },
            dataType: 'json'
        })
        .done(function( msg ) {
            RAD.data.log_code_pre = msg.loginValue.log_code;
            if(msg.error != "0" ){
                RAD.data.logged = false;
                f_fail(msg.error);
            } else {
                RAD.data.logged = false;
                try{
                    App.io.setNome(msg.loginValue.nome);
                    App.io.setCognome(msg.loginValue.cognome);
                    App.io.setTelefono(msg.loginValue.telefono);
                    App.io.setCitta(msg.loginValue.citta);
                    App.io.setAc_pr(msg.loginValue.ac_pr);
                    App.io.setAc_ne(msg.loginValue.ac_ne);
                } catch(err){
                }
                f_ok();
            }
        })
        .fail(function( jqXHR, textStatus ) {
                RAD.data.logged = false;
                App.io.log("Login Fail - " + textStatus);
                
                f_fail("R0");
            }
        );
    },
    req_cart_list: function(f_ok,f_fail){
        req_code = '20';
        $.ajax({
            type: "POST",
            url: RAD.data.url,
            data: {  
                req_code: req_code,
                email: RAD.data.email, 
                log_code: sha512("sha512" + RAD.data.password512 + RAD.data.log_code_pre)
            },
            dataType: 'json'
        })
        .done(function( msg ) {
            //App.io.log("Cartelle List Response ok");
            RAD.data.log_code_pre = msg.loginValue.log_code;
            if(msg.loginValue.logged == 0){
                //App.io.log("Cartelle List  not logged");
                RAD.data.logged = false;
                f_fail();
            } else {
                //App.io.log("Cartelle List  ok");
                var cart_list = msg["dati"];
                f_ok(cart_list);
            }
        })
        .fail(function( jqXHR, textStatus ) {
                RAD.data.logged = false;
                App.io.log("Cartelle List  Fail - " + textStatus);
                f_fail();
            }
        );
    },
    req_cart_add: function(f_ok,f_fail,cart){
        req_code = '21';
        $.ajax({
            type: "POST",
            url: RAD.data.url,
            data: {  
                req_code: req_code,
                email: RAD.data.email, 
                lg: RAD.data.log_code_pre,
                log_code: sha512("sha512" + RAD.data.password512 + RAD.data.log_code_pre),
                id_ttr: cart["id_ttr"],
                tr_anno: cart["tr_anno"],
                tr_ruolo: cart["tr_ruolo"],
                tr_notifica: cart["tr_notifica"],
                tr_importo: cart["tr_importo"],
                note: cart["note"]
            },
            dataType: 'json'
        })
        .done(function( msg ) {
            RAD.data.log_code_pre = msg.loginValue.log_code;
            App.outGoing();
            if(msg.loginValue.logged == 0){
                RAD.data.logged = false;
                try{
                    f_fail(0);
                } catch(e){
                    
                }
                
            } else {
                if(msg.error != 0 && msg.error != "0"){
                    try{
                        f_fail(msg.error);
                    } catch(e){
                        
                    }
                    
                } else {
                    result = new Array();
                    result["elab_code"] = msg["dati"]["elab_code"];
                    result["elab_string"] = msg["dati"]["elab_string"];

                    result["res"] = 1;
                    App.page.cartelle.data.cartelleAgg = false;
                    try{
                        f_ok(result);
                    } catch(e){
                        
                    }
                    
                }
            }
        })
        .fail(function( jqXHR, textStatus ) {
                RAD.data.logged = false;
                App.outGoing();
                App.io.log(" Fail - " + textStatus);
                try{
                    f_fail(textStatus);
                } catch(e){

                }
            }
        );
    },
    req_send_msg: function(f_ok,f_fail,title,message){
        req_code = '4';
        $.ajax({
            type: "POST",
            url: RAD.data.url,
            data: {  
                req_code: req_code,
                email: RAD.data.email, 
                log_code: sha512("sha512" + RAD.data.password512 + RAD.data.log_code_pre),
                titolo: title,
                msg: message,
                telefono: ""
            },
            dataType: 'json'
        })
        .done(function( msg ) {
            RAD.data.log_code_pre = msg.loginValue.log_code;
            if(msg.loginValue.logged == 0){
                RAD.data.logged = false;
                f_fail();
            } else {
                if(msg.error == 0){
                    f_ok();
                } else {
                    f_fail();
                }
            }
        })
        .fail(function( jqXHR, textStatus ) {
                RAD.data.logged = false;
                //App.io.log("Send msg  Fail - " + textStatus);
                f_fail();
            }
        );
    },
    req_rep_pass: function(f_ok,f_fail){
        req_code = '6';
        App.io.log("REQ PASSWORD");
        $.ajax({
            type: "POST",
            url: RAD.data.url,
            data: {  
                req_code: req_code,
                email: RAD.data.email
                //log_code: sha512("sha512" + RAD.data.password512 + RAD.data.log_code_pre)
            },
            dataType: 'json'
        })
        .done(function( msg ) {
            if(msg.code_ans == 1){
                try{
                    f_ok();
                } catch(err) {
                    
                }
                App.io.log("Rec PASSWORD OK");
            } else {
                try{
                    f_fail();
                } catch(err) {
                    
                }
                App.io.log("Rec PASSWORD FAIL 1");
            }
        })
        .fail(function( jqXHR, textStatus ) {
                RAD.data.logged = false;
                //App.io.log("Send msg  Fail - " + textStatus);
                try{
                    f_fail();
                } catch(err) {
                    
                }
                App.io.log("REC PASSWORD FAIL 0");
            }
        );
    },
    req_change_data_password: function(f_ok,f_fail,pa,p2){
        req_code = '7';
        App.io.log("REQ Change Password");
        $.ajax({
            type: "POST",
            url: RAD.data.url,
            data: {  
                req_code: req_code,
                email: RAD.data.email,
                log_code: sha512("sha512" + RAD.data.password512 + RAD.data.log_code_pre),
                pa: pa,
                p2: p2
            },
            dataType: 'json'
        })
        .done(function( msg ) {
            RAD.data.log_code_pre = msg.loginValue.log_code;
            if(msg.loginValue.logged == 0){
                RAD.data.logged = false;
            }
            if(msg.code_ans == 1){
                RAD.set_password(p2);
                App.io.setPassword512(p2);
                App.option.save();
                try{
                    f_ok();
                } catch(err) {
                    
                }
                App.io.log("Change Pass OK");
            } else {
                try{
                    f_fail(msg.errore);
                } catch(err) {
                    
                }
                App.io.log("Change Pass FAIL D1");
            }
        })
        .fail(function( jqXHR, textStatus ) {
                RAD.data.logged = false;
                try{
                    f_fail();
                } catch(err) {
                    
                }
                App.io.log("Change Pass FAIL D0");
            }
        );
    },
    req_change_data_profilo: function(f_ok,f_fail,dato,valore){
        
    },
    req_change_data_newsLetter: function(f_ok,f_fail,val){
        
    },
    req_help(){
        
    }
};